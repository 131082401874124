import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import BottomContent from '../components/bottomContent';
import HeroGallery from '../components/HeroGallery';
import Carousel from '../components/carousel';
import { Lightbox } from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

const contexts = {
    headshots: require.context('../resources/images/headshots', true),
    corporate: require.context('../resources/images/corporate', true),
    portraits: require.context('../resources/images/portraits', true),
    products: require.context('../resources/images/products', true),
    events: require.context('../resources/images/events', true)
};

function getUniqueRandomNumbers(count, numImages) {
    const uniqueNumbers = new Set();
    while (uniqueNumbers.size < Math.min(count, numImages)) {
        uniqueNumbers.add(Math.floor(Math.random() * count));
    }
    const uniqueArray = Array.from(uniqueNumbers);
    while (uniqueArray.length < numImages) {
        uniqueArray.push(uniqueArray[Math.floor(Math.random() * uniqueArray.length)]);
    }
    return uniqueArray;
}

function Photography() {
    const [curDescriptor, setCurDescriptor] = useState('events');
    const [refreshKey, setRefreshKey] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const numImages = 36;
    const images = contexts[curDescriptor];
    const imageList = images.keys().map(image => images(image));

    // Generate random numbers once per category selection (not on lightbox open)
    const [uniqueNumbers, setUniqueNumbers] = useState([]);
    useEffect(() => {
        try {
            setUniqueNumbers(getUniqueRandomNumbers(imageList.length, numImages));
        } catch (error) {
            console.error(error.message);
        }
    }, [curDescriptor, imageList.length]); // Only re-run when the descriptor changes

    const handleDescriptorClick = (descriptor) => {
        if (curDescriptor === descriptor) {
            setRefreshKey(prevKey => prevKey + 1);
        } else {
            setCurDescriptor(descriptor);
        }
    };
    useEffect(() => {
        try {
            setUniqueNumbers(getUniqueRandomNumbers(imageList.length, numImages));
        } catch (error) {
            console.error(error.message);
        }
    }, [curDescriptor, refreshKey, imageList.length]);
    const openLightbox = (index) => {
        setCurrentIndex(index);
        setIsOpen(true);
    };

    return (
        <div className="photography">
            <Helmet>
                <title>Photography | Mason Ayres Creative</title>
                <meta name="description" content="Showcasing stunning, professional photography." />
                <meta name="keywords" content="Photography, Portraits, Headshots, corporate photography, product photography" />
                <meta name="title" content="Photography | Mason Ayres Creative" />
                <meta property="og:title" content="Photography | Mason Ayres Creative" />
            </Helmet>

            <div className='descriptorsOuter'>
                <HeroGallery />
                <h1 className='photoh1'>PHOTOGRAPHY BY MASON AYRES CREATIVE</h1>
                <div className='descriptorsInner'>
                    <a className={curDescriptor === 'headshots' ? 'active' : ''} onClick={() => handleDescriptorClick('headshots')} href="#headshots">HEADSHOTS</a>
                    <a className={curDescriptor === 'corporate' ? 'active' : ''} onClick={() => handleDescriptorClick('corporate')} href="#corporate">CORPORATE/BRANDING</a>
                    <a className={curDescriptor === 'portraits' ? 'active' : ''} onClick={() => handleDescriptorClick('portraits')} href="#portraits">PORTRAITS</a>
                    <a className={curDescriptor === 'products' ? 'active' : ''} onClick={() => handleDescriptorClick('products')} href="#products">PRODUCTS</a>
                    <a className={curDescriptor === 'events' ? 'active' : ''} onClick={() => handleDescriptorClick('events')} href="#events">EVENTS</a>
                </div>
                <p className="descriptorsInner-caption">Click a category to see a random gallery of images. Images will reload upon clicking a category twice.</p>
            </div>

            {uniqueNumbers.length > 0 ? (
                <div className='imageContainer'>
                    <div className='leftImages'>
                        {uniqueNumbers.slice(0, 12).map((num, index) => (
                            <img 
                                key={`${curDescriptor}-${index}`} 
                                src={imageList[num]} 
                                alt={`${curDescriptor}${index + 1}`} 
                                onClick={() => openLightbox(index)}
                            />
                        ))}
                    </div>
                    <div className='middleImages'>
                        {uniqueNumbers.slice(12, 22).map((num, index) => (
                            <img 
                                key={`${curDescriptor}-${index + 12}`} 
                                src={imageList[num]} 
                                alt={`${curDescriptor}${index + 13}`} 
                                onClick={() => openLightbox(index + 12)}
                            />
                        ))}
                    </div>
                    <div className='rightImages'>
                        {uniqueNumbers.slice(22, 36).map((num, index) => (
                            <img 
                                key={`${curDescriptor}-${index + 22}`} 
                                src={imageList[num]} 
                                alt={`${curDescriptor}${index + 23}`} 
                                onClick={() => openLightbox(index + 22)}
                            />
                        ))}
                    </div>
                </div>
            ) : (
                <p>Not enough images available in this category.</p>
            )}

            {/* Lightbox Component */}
            <Lightbox 
                open={isOpen}
                close={() => setIsOpen(false)}
                slides={uniqueNumbers.map(num => ({ src: imageList[num] }))} // Correct format for lightbox
                index={currentIndex} // Start from the clicked image
                animation={{ transition: 'zoom' }} 
            />

            <div className='DividerLine'></div>
            <section className=''>
            <h2 className='photographyh2'>PORTRAIT PHOTOGRAPHY</h2>
            <p className='photographyP'>Looking for Portrait Photography that captures the moment? 
                What makes a portrait special is preserving the personality, or soul of the people within it. That requires a photographer that is willing
                 to communicate with you to get the essence of who you are. That's everything from <ul className='photographyList'><li>Location</li><li>Expression</li><li>Outfit</li><li>Flavor of Edit</li><li>Props &amp; Style</li><li>Color</li></ul>to get a cohesive body of work that fits you! The goal is to communicate properly to provide what you need and combine it with artistic vision to make stunning headshots, and portraits that make the people feel like who they are.</p>
            </section>
            <section className=''>
            <h2 className='photographyh2'>EVENT PHOTOGRAPHY</h2>
            <p className='photographyP'>If you're looking for coverage, we have a whole lot. More is always better, you never want to miss a moment of the events that make life special. Corporate Events, Weddings, Birthdays, nonprofit events, sporting events, you name it. Packages specifically tailored to you and your needs.</p><p className='photographyP' style={{fontSize: '1em'}}>Looking for videography as well? Check out our <a href="./videography" target="_blank">Videography Page!</a></p>
               
            </section>
            <section className=''>
            <h2 className='photographyh2'>PHOTOGRAPHY GEAR</h2>
            <p className='photographyP'>A photographer is nothing without their camera. For Photography, Mason Ayres Creative utilizes the <strong>Canon EOS R Platform</strong>, using camera body systems such as the <strong>EOS R, R5, R6mkii</strong> and others. Depending on the situation, we'll use both on-camera &amp; off-camera flash, powered by <strong>Godox.</strong></p>
            </section>
            <div className='DividerLine'></div>

            <Carousel />
            <BottomContent />
        </div>
    );
}

export default Photography;
